<template>
	<WDialog v-model="showDialog" :max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'" :title="$t('documents.edit_description')" @ready="onDialogReady" @close="close()">
		<v-layout row>
			<v-textarea ref="descriptionInput" v-model.trim="description" autofocus color="primary" :label="$t('description')" maxlength="191" />
		</v-layout>
		<template v-slot:actions>
			<v-spacer />
			<WButton flat icon="save" @click="save()">{{ $t('actions.save') }}</WButton>
		</template>
	</WDialog>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'EditDescriptionDialog',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: Object,
			required: false,
			default: null,
			validator: function (value) {
				let result = true
				if (value && !value.hasOwnProperty('description')) {
					result = 'Missing "description" property on object'
				} else if (value && !value.hasOwnProperty('type')) {
					result = 'Missing "type" property on object'
				}
				return result
			}
		}
	},
	data: function () {
		return {
			showDialog: true,
			description: ''
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		parentFolder: function () {
			return this.model ? this.model.parent : null
		},
		nodes: function () {
			return !Array.isArray(this.value) && this.value ? [this.value] : this.value
		}
	},
	mounted: function () {
		this.appEventBus.emit(this.appEvents.PAUSE_LISTENING_TO_KEYBOARD)
		this.description = this.model.description
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.RESUME_LISTENING_TO_KEYBOARD)
		this.close()
	},
	methods: {
		onDialogReady () {
			this.$nextTick(() => {
				this.$refs.descriptionInput.focus()
			})
		},
		close: function () {
			this.showDialog = false
			this.$emit('close')
		},
		save: function () {
			const updateAction = this.model.is_folder ? this.service.updateFolder : this.service.updateFolderDocument
			const folderId = this.parentFolder ? this.parentFolder.id : this.model.folder_id
			updateAction(this.vendorId, folderId, this.model, null, { description: this.description })
				.then(() => {
					this.model.description = this.description
					const message = this.model.is_folder ?
						this.$tc('documents.folder_updated', this.nodes.length) :
						this.$tc('documents.document_updated', this.nodes.length)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, message)
				})
				.finally(() => {
					this.close()
				})
		}
	}
}
</script>
